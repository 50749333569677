<template>
  <v-container class="container--fluid fill-height primary">
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-card class="elevation-5 px-3 pb-3">
          <v-card-text>
            <div class="layout column align-center">
              <h1 class="text-center my-14 primary--text">
                Prihlásenie
              </h1>

            </div>
            <v-form>
              <v-text-field
                v-model="model.email"
                append-icon="mdi-account"
                name="email"
                label="Email"
                type="email"
                required
                autocomplete="username"
                @keyup.enter="login"
              />
              <v-text-field
                v-model="model.password"
                append-icon="mdi-lock"
                name="password"
                label="Heslo"
                type="password"
                required
                autocomplete="current-password"
                @keyup.enter="login"
              />
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loading"
              @click="login"
            >
              Prihlásiť sa
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-snackbar
      v-model="error"
      top
      centered
      timeout="3000"
      color="red"
      transition="scale-transition"
    >
      {{ error_msg }}

    </v-snackbar>

  </v-container>
</template>

<script>

export default {
  name: 'Login',
  data: () => ({
    loading: false,
    error: false,
    error_msg: "",
    model: {
      email: "", //userAdmin.email,
      password: "" //userAdmin.password,
    },
  }),
  methods: {
    async login() {
        if(this.model.email == "verejnost@verejnost.com"){
          return
        }

        this.loading = true
        await this.$store.dispatch('LoginByEmail', {
            email: this.model.email,
            password: this.model.password
        }).then(() => {
            this.error = false
            this.loading = false

            this.$router.push({ name:"Dashboard" })
        }).catch(err =>{
            this.error_msg = err
            this.error = true
            this.loading = false
        })
    },
  },
  mounted(){
    if(this.$store.getters.loggedIn){
      this.$store.dispatch('Logout')
    }
  }
};
</script>